import React, {useContext} from "react"
//import { css, cx } from '@emotion/css'
import { Link } from "gatsby"
import { LangugaeContext } from "../../context/LanguageProvider";

const MainButton = (props) => {

  const language = useContext(LangugaeContext);

  return <div
    onClick={() => language.constructPath(props.to, language)}
    state={props.data ? props.data : null}
    role="button"
    {...props}
  >
    <button id={props.tag ? props.tag : null} className={props.tag ? "mainButton" + " " + props.tag : "mainButton"} onClick={props.onClick}>
      {props.text}
    </button>
  </div>
}

export default MainButton
