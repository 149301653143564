import React, {useContext} from "react"

import Layout from "../components/shared/Layout2"
import SEO from "../components/shared/seo"
import Section from "../components/shared/Section"
import SectionTitle from "../components/shared/SectionTitle"
import MainButton from "../components/shared/MainButton"
import Container from "../components/shared/Container"
import EndSection from "../components/shared/EndSection"
import NavbarNew from "../components/shared/NavbarNew"
import { graphql, StaticQuery } from 'gatsby';
import { LangugaeContext } from "../context/LanguageProvider";

function NotFoundPageTemplate(props) {

  const language = useContext(LangugaeContext);

  const svData = props.cmsData?.svNotfound?.edges[0]?.node?.frontmatter ?? {}
  const enData = props.cmsData?.enNotfound?.edges[0]?.node?.frontmatter ?? {}

  let cmsData = svData;
  language.swedish ? null : cmsData = enData;

  return (<Layout>
    <NavbarNew />

    <SEO title="404: Not found" />
    <Section imageUrl={'/img/pink.png'}>
      <Container>
        <SectionTitle color="white" text={cmsData.title} />
        <MainButton to={cmsData.button.path} text={cmsData.button.text} />
      </Container>
    </Section>


    <EndSection iconUrl={cmsData.endSection.imageIcon.childImageSharp.fluid.src} to={cmsData.endSection.button.path} title={cmsData.endSection.button.title} buttonText={cmsData.endSection.button.text} />
  </Layout>
)
  }


export default function NotFoundPage() {

  return (
    <StaticQuery
      query={graphql`
        query {
          svNotfound: allMarkdownRemark(filter: 
            {frontmatter: {templateKey: {eq: "svNotFound"}}}) {
            edges {
              node {
                frontmatter {
                  title
                  button {
                    text
                    path
                  }
                  endSection {
                    title
                    imageIcon {
                      childImageSharp {
                          fluid(maxWidth: 2048, quality: 100) {
                          ...GatsbyImageSharpFluid
                          }
                      }
            }
                    button {
                      text
                      path
                    }
                  }
                }
              }
            }
          },

          enNotfound: allMarkdownRemark(filter: 
            {frontmatter: {templateKey: {eq: "enNotfound"}}}) {
            edges {
              node {
                frontmatter {
                  title
                  button {
                    text
                    path
                  }
                  endSection {
                    title
                    imageIcon {
                      childImageSharp {
                          fluid(maxWidth: 2048, quality: 100) {
                          ...GatsbyImageSharpFluid
                          }
                      }
            }
                    button {
                      text
                      path
                    }
                  }
                }
              }
            }
          },
        }
      `}
      render={(data) => <NotFoundPageTemplate cmsData={data} />}
    />
  );
}